import {createBrowserRouter} from "react-router-dom";
import {lazy, Suspense} from "react";
import ScreenLoader from "../components/general/ScreenLoader";

const ProductsList = lazy(() => import("../Pages/Products/ProductsList.jsx"));
const ProductShowPage = lazy(() => import("../Pages/Products/ProductShowPage.jsx"));
const ProductCreatePage = lazy(() => import("../Pages/Products/ProductCreatePage.jsx"));
const ProductEditPage = lazy(() => import("../Pages/Products/ProductEditPage.jsx"));
const BrandsList = lazy(() => import("../Pages/Brands/BrandsList.jsx"));
const OrdersList = lazy(() => import("../Pages/Orders/OrdersList.jsx"));
const OrderCreatePage = lazy(() => import("../Pages/Orders/OrderCreatePage.jsx"));

const Layout = lazy(() => import("../Layout/Layout.jsx"));
const Dashboard = lazy(() => import("../Pages/Dashboard/Dashboard.jsx"));
const Banners = lazy(() => import("../Pages/Banners/Banners.jsx"));
const Categories = lazy(() => import("../Pages/Categories/Categories.jsx"));
const Discounts = lazy(() => import("../Pages/Discounts/Discounts.jsx"));
const AddDiscount = lazy(() => import("../Pages/Discounts/AddDiscount.jsx"));
const SetDiscount = lazy(() => import("../Pages/Discounts/SetDiscount.jsx"));
const Faq = lazy(() => import("../Pages/Faq/Faq.jsx"));
const Reviews = lazy(() => import("../Pages/Reviews/Reviews.jsx"));
const Users = lazy(() => import("../Pages/Users/Users.jsx"));
const Customers = lazy(() => import("../Pages/Customers/Customers.jsx"));
const Customer = lazy(() => import("../Pages/Customers/Customer.jsx"));
const EditCustomer = lazy(() => import("../Pages/Customers/EditCustomer.jsx"));
const AddUser = lazy(() => import("../Pages/Users/AddUser.jsx"));
const AddCustomer = lazy(() => import("../Pages/Customers/AddCustomer.jsx"));
const Attributes = lazy(() => import("../Pages/Attributes/Attributes.jsx"));
const InstallmentTerms = lazy(() => import("../Pages/InstallmentTerms/InstallmentTermsList.jsx"));
const OrderSources = lazy(() => import("../Pages/OrderSources/OrderSourcesList.jsx"));
const UpdateDiscount = lazy(() => import("../Pages/Discounts/UpdateDiscount.jsx"));
const Login = lazy(() => import("../Pages/Login.jsx"));


export const routerNames = {
  Dashboard: "/dashboard",
  Banners: "banners",
  ProductsList: "products",
  ProductCreatePage: "products/create",
  ProductShowPage: "products/:id",
  ProductEditPage: "products/:id/edit",
  OrdersList: "orders",
  OrderCreate: "orders/create",
  // AddOrder: "orders/add",
  Categories: "categories",
  Users: "users",
  Customers: "customers",
  Customer: "customers/:id",
  EditCustomer: "customers/:id/edit",
  CreateCustomer: "/dashboard/customers/create",
  AddUser: "/dashboard/users/addUser",
  BrandsList: "brands",
  Discounts: "discounts",
  AddDiscount: "discounts/addDiscount",
  SetDiscount: "discounts/:id/SetDiscount",
  Reviews: "Reviews",
  Faq: "faq",
  UpdateDiscount: "discount",
  Attributes: "attributes",
  InstallmentTerms: "installment-terms",
  OrderSources: "order-sources",
};

export const router = createBrowserRouter([
  {
    index: true,
    element: (
      <Suspense fallback={<ScreenLoader/>}>
        <Login/>
      </Suspense>
    ),
  },
  {
    path: routerNames.Dashboard,
    element: (
      <Suspense fallback={<ScreenLoader/>}>
        <Layout/>
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<ScreenLoader/>}>
            <Dashboard/>
          </Suspense>
        ),
      },
      {
        path: routerNames.Banners,
        element: (
          <Suspense fallback={<ScreenLoader/>}>
            <Banners/>
          </Suspense>
        ),
      },
      {
        path: routerNames.ProductsList,
        element: (
          <Suspense fallback={<ScreenLoader/>}>
            <ProductsList/>
          </Suspense>
        ),
      },
      {
        path: routerNames.ProductCreatePage,
        element: (
          <Suspense fallback={<ScreenLoader/>}>
            <ProductCreatePage/>
          </Suspense>
        ),
      },
      {
        path: routerNames.ProductShowPage,
        element: (
          <Suspense fallback={<ScreenLoader/>}>
            <ProductShowPage/>
          </Suspense>
        ),
      },
      {
        path: routerNames.ProductEditPage,
        element: (
          <Suspense fallback={<ScreenLoader/>}>
            <ProductEditPage/>
          </Suspense>
        ),
      },
      {
        path: routerNames.OrdersList,
        element: (
          <Suspense fallback={<ScreenLoader/>}>
            <OrdersList/>
          </Suspense>
        ),
      },
      {
        path: routerNames.OrderCreate,
        element: (
          <Suspense fallback={<ScreenLoader/>}>
            <OrderCreatePage/>
          </Suspense>
        ),
      },
      {
        path: routerNames.Categories,
        element: (
          <Suspense fallback={<ScreenLoader/>}>
            <Categories/>
          </Suspense>
        ),
      },
      {
        path: routerNames.BrandsList,
        element: (
          <Suspense fallback={<ScreenLoader/>}>
            <BrandsList/>
          </Suspense>
        ),
      },
      {
        path: routerNames.Discounts,
        element: (
          <Suspense fallback={<ScreenLoader/>}>
            <Discounts/>
          </Suspense>
        ),
      },
      {
        path: routerNames.AddDiscount,
        element: (
          <Suspense fallback={<ScreenLoader/>}>
            <AddDiscount/>
          </Suspense>
        ),
      },
      {
        path: routerNames.SetDiscount,
        element: (
          <Suspense fallback={<ScreenLoader/>}>
            <SetDiscount/>
          </Suspense>
        ),
      },
      {
        path: routerNames.Faq,
        element: (
          <Suspense fallback={<ScreenLoader/>}>
            <Faq/>
          </Suspense>
        ),
      },
      {
        path: routerNames.Reviews,
        element: (
          <Suspense fallback={<ScreenLoader/>}>
            <Reviews/>
          </Suspense>
        ),
      },
      {
        path: routerNames.Users,
        element: (
          <Suspense fallback={<ScreenLoader/>}>
            <Users/>
          </Suspense>
        ),
      },
      {
        path: routerNames.Customers,
        element: (
          <Suspense fallback={<ScreenLoader/>}>
            <Customers/>
          </Suspense>
        ),
      },
      {
        path: routerNames.Customer,
        element: (
          <Suspense fallback={<ScreenLoader/>}>
            <Customer/>
          </Suspense>
        ),
      },
      {
        path: routerNames.EditCustomer,
        element: (
          <Suspense fallback={<ScreenLoader/>}>
            <EditCustomer/>
          </Suspense>
        ),
      },
      {
        path: routerNames.AddUser,
        element: (
          <Suspense fallback={<div>loading...</div>}>
            <AddUser/>
          </Suspense>
        ),
      },
      {
        path: routerNames.CreateCustomer,
        element: (
          <Suspense fallback={<div>loading...</div>}>
            <AddCustomer/>
          </Suspense>
        ),
      },
      {
        path: `${routerNames.UpdateDiscount}/:id`,
        element: (
          <Suspense fallback={<ScreenLoader/>}>
            <UpdateDiscount/>
          </Suspense>
        ),
      },
      {
        path: routerNames.Attributes,
        element: (
          <Suspense fallback={<ScreenLoader/>}>
            <Attributes/>
          </Suspense>
        ),
      },
      {
        path: routerNames.InstallmentTerms,
        element: (
          <Suspense fallback={<ScreenLoader/>}>
            <InstallmentTerms/>
          </Suspense>
        ),
      },
      {
        path: routerNames.OrderSources,
        element: (
          <Suspense fallback={<ScreenLoader/>}>
            <OrderSources/>
          </Suspense>
        ),
      },
    ],
  },
]);
